import React, { useContext, useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { LangContext } from "../context/langContext"
import { TextContext } from "../context/textContext"
import Lottie from 'react-lottie';
import animationData from '../images/home/CharecterAnimated.json'
import arrowDown from '../images/home/arrowDown.svg'
import { FormattedMessage } from "react-intl";
import axios from 'axios';
import Loader from "../components/loader"
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import Img from "gatsby-image"
import Recaptcha from 'react-recaptcha'

const IndexPage = ({data}) => {

  const { lang } = useContext(LangContext);
  const { text } = useContext(TextContext);

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMinYMin'
    }
  };

  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [checked, setChecked] = useState(false);

  const [isloading, setIsloading] = useState(false);

  const { name, email, phone, message } = state;
  
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  // render on captcha load
  const handleCaptchaLoad = event => { return };

  // load on callback verify
  const verifyCallback = () => { setChecked(true); } 


  const handleSubmit = (e) => {
    e.preventDefault();
    if(!checked){
      store.addNotification({
        title: 'Oops',
        message: 'You have to check Recaptcha!',
        type: 'danger',                         // 'default', 'success', 'info', 'warning'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 4000 
        }
      })
    }
    else{
      setIsloading(true);
      let jsonData = JSON.stringify(state);
      axios.post(`https://dashboard.almootamayezoon.com/index.php/examples/addContact`, jsonData).then(() => {
        setState({name:'', email:'',phone:'',message:''})
        store.addNotification({
          title: 'Message Sent Successfully',
          message: 'Thanks',
          type: 'warning',
          container: 'top-right',
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 4000 
          }
        })
        setIsloading(false);
        setChecked(false);
      })
    }
  }

  const fetch = (key) => {
    return text.length === 0 ? '' : lang==='en'? text.find(el => el.lang_key === key).en_value : text.find(el => el.lang_key === key).ar_value;
  }

  return(
    <Layout>
      <SEO title="Home" />
      <div className="arrowDown d-none d-lg-block">
        <img src={arrowDown} alt="arrow" width="100%"/>
      </div>
      <div className="row justify-content-end d-none d-lg-flex">
        <div className={lang==='en' ? "col-5 px-0 paddingTop": "col-5 px-0 text-right paddingTop"}>
          <Img fluid={lang==='en'? data.CoverLogo.childImageSharp.fluid:data.LogoArabic03.childImageSharp.fluid } alt="almootamayezoon" style={{width:"70%"}} />
          {/* <p className="mix links mt-3"><FormattedMessage id="chocolate" /></p> */}
          <p className="mix links mt-3">{fetch('1home1')}</p>
          <p className={lang==='en'?"body grey pr-5 mr-5":"body grey pl-5 ml-5"}>{fetch('1home2')}</p>
          <Link to="/about/"><button className="primaryBtn header2 mt-3"><FormattedMessage id="readMore" /></button></Link>
        </div>
        <div className={lang==='en'?"col-6 text-left pl-0 pr-5":"col-6 text-left pr-0 pl-5"}>
          <Img fluid={data.CoverArt.childImageSharp.fluid} alt="Cover" className="text-center"/>
        </div>
      </div>
      {/* //////////////  Mobile Design //////////// */}
      <div className="row justify-content-center d-lg-none paddingTop">
        <div className="col-12 col-md-10 bg">
          <Img fluid={lang==='en'?data.AboutUs_Cover.childImageSharp.fluid:data.AboutUs_CoverAr.childImageSharp.fluid} alt="Cover" />
        </div>
        <div className="col-11 col-md-10">
          <p className="mix links mt-3">{fetch('1home1')}</p>
          <p className={lang==='en'?"body grey pl-2 pr-5":"body grey pl-5 pr-2"}>{fetch('1home2')}</p>
          <Link to="/about/"><button className="primaryBtn header2 mt-3"><FormattedMessage id="readMore" /></button></Link>
        </div>
      </div>
      {/* ///////////////////// Mobil ////////// */}
      <div className="spacer mb-5"></div>
      <div className="row justify-content-center">
        <div className="col-35 text-left px-0 pt-5 d-none d-lg-block">
          <Img fluid={data.image9.childImageSharp.fluid} alt="not just a chocolate" style={{height:"75%"}}/>
        </div>
        <div className="col-12 col-lg-5 notJust">
          <div className="txtContainer">
            <p className="header1 yellow">{fetch('1home3')}</p>
            <p className="body white mb-0">{fetch('1home4')}</p>
            <Link to="/about/"><button className="primaryBtn header2"><FormattedMessage id="readMore" /></button></Link>
          </div>
        </div>
        <div className="col-35 text-right px-0 pt-5 d-none d-lg-block">
          <Img fluid={data.image11.childImageSharp.fluid} alt="not just a chocolate" style={{height:"75%"}}/>
        </div>
      </div>
      <div className="text-center bg">
        <p className="header1 yellow">{fetch('1home5')}</p>
        <p className="body grey">{fetch('1home6')}</p>
      </div>
      <div className="row text-center pr-row justify-content-center">
        <div className="col-6 col-lg-3">
          <Img fluid={data.Pic06.childImageSharp.fluid} alt="Pic06" />
        </div>
        <div className="col-6 col-lg-3">
          <Img fluid={data.Pic07.childImageSharp.fluid} alt="Pic07" />
        </div>
        <div className="col-6 col-lg-3">
          <Img fluid={data.Pic08.childImageSharp.fluid} alt="Pic08" />
        </div>
        <div className="col-6 col-lg-3">
          <Img fluid={data.Pic09.childImageSharp.fluid} alt="Pic09" />
        </div>
      </div>
      <div className="spacer"></div>
      <div className="visitOur text-center">
        <p className="header2 grey pad">{fetch('1home7')}</p>
        <div className="row justify-content-center pt-5">
          <div className="col-10 col-md-6 col-lg-3 my-3 my-lg-0">
          <Img fluid={data.FancyLogo.childImageSharp.fluid} alt="fancy" style={{width:"65%", margin:"0 auto"}} />
          </div>
          <div className="col-10 col-md-6 col-lg-3 my-3 my-lg-0">
          <Img fluid={data.SweetnessLogo.childImageSharp.fluid} alt="sweetness" style={{width:"90%", margin:"0 auto"}} />
          </div>
        </div>
        <Link to="/products/"><button className="primaryBtn header2 mt-5"><FormattedMessage id="ourProducts" /></button></Link>
      </div>
      <div className="spacer"></div>
      <div className="formContainer">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-5 firstCol">
            { isloading && <Loader />}
            { !isloading &&
              <form className="form" onSubmit={(e)=> handleSubmit(e)}>
                <input type="text" name="name" className="form-control body" placeholder={lang==='en'? "Name":"الاسم"} required autoComplete="off" value={name} onChange={handleChange} />
                <input type="email" name="email" className="form-control body" placeholder={lang==='en'?"Email":"البريد الالكتروني"} required autoComplete="off" value={email} onChange={handleChange} />
                <input type="tel" className="form-control body" name="phone" placeholder={lang==='en'?"Phone Number":"رقم الهاتف"} pattern="^[0-9]*$" required autoComplete="off" value={phone} onChange={handleChange} />
                <textarea type="text" name="message" rows="2" className="form-control body" placeholder={lang==='en'?"Message":"الرسالة"} value={message} onChange={handleChange} />
                <div className="mt-4">
                  <Recaptcha
                    sitekey="6LfPwtYUAAAAAHMp-b2MAh7Fu0PpIVLUVQaVvcpo"
                    render="explicit"
                    theme="dark"
                    verifyCallback={verifyCallback}
                    onloadCallback={handleCaptchaLoad}
                  />
                </div>
                <button className="primaryBtn header2 float-right mt-3" type="submit"><FormattedMessage id="send" /></button>
              </form>
            }
          </div>
          <div className="col-12 col-lg-4 secondCol">
            <p className="header1 white"><FormattedMessage id="have" /></p>
            <p className="body white"><FormattedMessage id="weAreAlways" /></p>
          </div>
        </div>
      </div>
      <div className="spacer"></div>
      <div className="row justify-content-center getIn mt-5 mt-lg-0">
        <div className="col-12 col-lg-5 px-5">
          <Lottie options={defaultOptions} width="100%" height="100%" />
        </div>
        <div className="col-10 col-lg-4 mx-5 textCol">
          <p className="header1 yellow"><FormattedMessage id="getIn" /></p>
          <p className="body grey my-3">{fetch('1home8')}</p>
          <Link to="/contact/"><button className="primaryBtn header2 mt-5"><FormattedMessage id="getInTouch" /></button></Link>
        </div>
      </div>
      <div className="spacer"></div>
    </Layout>
  )
}

export default IndexPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 75) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    CoverArt: file(relativePath: { eq: "home/CoverArt.png" }) {
      ...fluidImage
    }
    CoverLogo: file(relativePath: { eq: "home/CoverLogo.png" }) {
      ...fluidImage
    }
    LogoArabic03: file(relativePath: { eq: "home/LogoArabic03.png" }) {
      ...fluidImage
    }
    AboutUs_Cover: file(relativePath: { eq: "about/AboutUs_Cover.png" }) {
      ...fluidImage
    }
    AboutUs_CoverAr: file(relativePath: { eq: "about/test.png" }) {
      ...fluidImage
    }
    image9: file(relativePath: { eq: "home/image 9.png" }) {
      ...fluidImage
    }
    image11: file(relativePath: { eq: "home/image 11.png" }) {
      ...fluidImage
    }
    Pic06: file(relativePath: { eq: "home/Pic06.png" }) {
      ...fluidImage
    }
    Pic07: file(relativePath: { eq: "home/Pic07.png" }) {
      ...fluidImage
    }
    Pic08: file(relativePath: { eq: "home/Pic08.png" }) {
      ...fluidImage
    }
    Pic09: file(relativePath: { eq: "home/Pic09.png" }) {
      ...fluidImage
    }
    FancyLogo: file(relativePath: { eq: "home/FancyLogo.png" }) {
      ...fluidImage
    }
    SweetnessLogo: file(relativePath: { eq: "home/SweetnessLogo.png" }) {
      ...fluidImage
    }
  }
`;